/* global config, Promise, angular */

'use strict';

angular.module('managerApp').factory('$_PrepaidAccounts', function (http, toast) {
    const url = config.apiURL + 'prepaid-accounts/';
    function errorHandler(err) {
        if (err) {
            if (err.message) {
                if (err.type !== 'HTTPService') {
                    return Promise.reject(err.message);
                } else {
                    toast.show(err.message, 'error', true);
                }
            } else {
                if (err.data && err.data.message) {
                    return Promise.reject(err.data);
                }
            }
            return Promise.reject(err);
        }
        return Promise.reject();
    }
    return {
        get: (id = null) => {
            let _url = url;
            if (id !== null) {
                _url += id;
            }
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    resolve(res.data);
                }).catch(errorHandler);
            });
        },
        add: (item) => {
            return http.post(url, item, {
                headers: {'Content-Type': undefined}
            }).then((res) => {
                return Promise.resolve(res);
            }).catch(errorHandler);
        },
        update: (id, data) => {
            if (id === null) {
                return Promise.reject('NoData');
            }
            let _url = url + id;
            return new Promise((resolve, reject) => {
                http.put(_url, data).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        delete: (item) => {
            return http.delete(url + (item._id ? item._id : item)).then((res) => {
                return Promise.resolve(res);
            }).catch(errorHandler);
        },
        getEvents: (id, by, page = 1, pageSize) => {
            if (!id) {
                return Promise.reject('NoData');
            }
            let _url = url + id + '/events', params = [];
            if (by) {
                for (let i in by) {
                    params.push('filter[' + i + ']=' + by[i]);
                }
            }
            if (page > 1) {
                params.push('page=' + parseInt(page));
            }
            if (pageSize) {
                params.push('pageSize=' + parseInt(pageSize));
            }
            if (params.length > 0) {
                _url += '?' + params.join('&');
            }
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    resolve(res.data);
                }).catch(errorHandler);
            });
        },
        credit: (prepaidAccountId = null, data = null) => {
            if (prepaidAccountId === null || data === null) {
                return Promise.reject('NoData');
            }
            let _url = url + prepaidAccountId + '/credit';
            return new Promise((resolve, reject) => {
                http.put(_url, data).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        getPaymentRequests: (data, pagination) => {
            let _url = url + data.prepaidAccountId + '/payment-requests';
            let params = ['filter[deviceType]=' + data.deviceType];
            if(data.completed === 1){
                params.push('filter[completed]=1');
            } else if(data.pending === 1){
                params.push('filter[pending]=1');
            }
            if (pagination.page > 1) {
                params.push('page=' + parseInt(pagination.page));
            }
            if (pagination.pageSize) {
                params.push('pageSize=' + parseInt(pagination.pageSize));
            }
            if (params.length > 0) {
                _url += '?' + params.join('&');
            }
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (res.data.count && res.data.rows) {
                        resolve({
                            count: res.data.count,
                            items: res.data.rows
                        });
                    }
                    resolve(res.data);
                }).catch(errorHandler);
            });
        },
        validateOrRejectPaymentRequest: (prepaidAccountId = null, paymentRequestId = null, action = null, data) => {
            let _url = url + prepaidAccountId + '/payment-requests/' + paymentRequestId + '/' + action;
            return new Promise((resolve, reject) => {
                http.put(_url, data).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        creditDevicePrepaidBalance(prepaidAccountId = null, deviceId = null, data = null) {
            let _url = url + prepaidAccountId + '/devices/' + deviceId + '/credit';
            return new Promise((resolve, reject) => {
                http.put(_url, data).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        }
    };
});